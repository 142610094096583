<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name" style="width: 25%">
              <a-input v-model="queryParams.equipment_name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="计划完成时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="维保单位" prop="maintenance_unit" style="width: 25%">
                <a-select v-model="queryParams.maintenance_unit" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in maintenanceUnitList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养完成时段" prop="queryInstallDate1" style="width: 25%">
                <a-range-picker v-model="queryInstallDate1"></a-range-picker>
              </a-form-model-item>
              <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
                <a-input v-model="queryParams.monitorpointname"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
                <a-select v-model="queryParams.equipment_type">
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否为消防设备" prop="is_fire_equipment" style="width: 25%">
                <a-select v-model="queryParams.is_fire_equipment">
                  <a-select-option value="是">是</a-select-option>
                  <a-select-option value="否">否</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="安装位置" prop="address" style="width: 25%">
                <a-input v-model="queryParams.address"></a-input>
              </a-form-model-item>
              <a-form-model-item label="防火分区" prop="fire_compartment" style="width: 25%">
                <a-input v-model="queryParams.fire_compartment"></a-input>
              </a-form-model-item>
              <a-form-model-item label="保养类型" prop="maintenance_schedule_type" style="width: 25%">
                <a-select  v-model.trim="queryParams.maintenance_schedule_type" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item,index) in equipmentMaintenanceItemList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保单位" prop="maintenance_unit_name" style="width: 25%">
<!--                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="deptCascaderSelected" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
                <a-input v-model="queryParams.maintenance_unit_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="保养单号" prop="order_num" style="width: 25%">
                <a-input v-model="queryParams.order_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="节点状态" prop="process_node" style="width: 25%">
                <a-select v-model="queryParams.process_node">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="20">待组长派单</a-select-option>
                  <a-select-option value="30">待组员派单</a-select-option>
                  <a-select-option value="40">待组员转单</a-select-option>
                  <a-select-option value="50">待签到</a-select-option>
                  <a-select-option value="60">待维保</a-select-option>
                  <a-select-option value="70">待组长审核</a-select-option>
                  <a-select-option value="71">完成</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维/自保组名称" prop="maintenancegroupname" style="width: 25%">
                <a-input v-model="queryParams.maintenancegroupname"></a-input>
              </a-form-model-item>
              <a-form-model-item label="任务年份" prop="year" style="width: 25%">
                <a-select v-model="queryParams.order_date">
                  <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="作业时间状态" prop="order_exception" style="width: 25%">
                <a-select v-model="queryParams.order_exception">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">异常</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养结论" prop="result_code" style="width: 25%">
                <a-select v-model="queryParams.result_code">
                  <a-select-option value="1">正常</a-select-option>
                  <a-select-option value="2">保养后正常</a-select-option>
                  <a-select-option value="3">报修（带病运行）</a-select-option>
                  <a-select-option value="4">报修（停止运行）</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保自评价" prop="group_man_audit_grade" style="width: 25%">
                <a-select v-model="queryParams.group_man_audit_grade">
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                  <a-select-option value="3">良好</a-select-option>
                  <a-select-option value="4">优秀</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="物业评价" prop="property_audit_grade" style="width: 25%">
                <a-select v-model="queryParams.property_audit_grade">
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                  <a-select-option value="3">良好</a-select-option>
                  <a-select-option value="4">优秀</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
<!--            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="maintenance_order_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="need_finish_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="process_node" slot-scope="value">
            <a-tag :color="orderStepColor[value]||''">{{orderSteps[value]||''}}</a-tag>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="maintenance_period" slot-scope="value">
            <span :title="value?maintenancePeriodList[value].dicvalue:''">{{value?maintenancePeriodList[value].dicvalue:''}}</span>
          </span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
          <span slot="plan_status" slot-scope="value">
            <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
          </span>
          <span slot="maintenance_schedule_type" slot-scope="value,record">{{equipmentMaintenanceItemList[value-1]?equipmentMaintenanceItemList[value-1].dicvalue:''}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenance_order_id">详情</a-menu-item>
<!--                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.maintenance_order_id">修改</a-menu-item>-->
                <a-menu-item v-if="btnList.includes('评价')&&(!record.property_audit_grade||!record.property_audit_evaluation)" :key="'evaluate-'+record.maintenance_order_id">评价</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.maintenance_order_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="evaluationModalVisible" title="评价" :width="430" :centered="true" @cancel="evaluationModalVisible=false">
      <template slot="footer">
        <a-button @click="evaluationModalVisible=false">关闭</a-button>
        <a-button @click="evaluation()" type="primary">保存</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:17}">
        <a-form-model-item label="物业评价" class="nobg" prop="property_audit_grade">
          <a-radio-group v-model="formData.property_audit_grade" style="width: 400px">
            <a-radio :value="1">不合格</a-radio>
            <a-radio :value="2">合格</a-radio>
            <a-radio :value="3">良好</a-radio>
            <a-radio :value="4">优秀</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="物业评价内容" prop="property_audit_evaluation">
          <a-textarea v-model="formData.property_audit_evaluation"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/plan/addOrEditModal";
import {
  deleteMaintenanceOrder, exportMaintenanceOrderListExcel,
  getMaintenanceOrderListByCondition,
  getMaintenanceOrderListByMaintenanceGroupName,
  getMaintenanceUnitListByCondition,
  modifyMaintenanceOrder,
} from "A/maintenance";
import {orderStepColor, orderStepList, orderSteps} from "@/json/orderSteps";
import DetailModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/task/DetailModal";
import {getMaintenanceGroupByUserId} from "A/xtpz";
import {orderException, orderExceptionColors} from "@/json/maintenance";
export default {
  name: "maintenanceTask",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    DetailModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      orderStepList,
      orderSteps,
      orderStepColor,
      orderException,
      orderExceptionColors,
      maintenanceUnitList:[],
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        is_fire_equipment:'',
        equipment_name:'',
        order_num:'',
        maintenance_unit_id:'',
        maintenance_unit_name:'',
        maintenance_unit:'',
        fire_compartment:'',
        address:'',
        order_date:'',
        year:'',
        starttime:'',
        endtime:'',
        maintenancegroupname:'',
        order_exception:'',
        result_code:'',
        group_man_audit_grade:'',
        property_audit_grade:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '保养单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '安装位置',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '防火分区',
          dataIndex: 'fire_compartment',
          key: 'fire_compartment',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
          width: 250,
        },
        {
          title: '维/自保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '维/自保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '保养周期',
          dataIndex: 'maintenance_period',
          key: 'maintenance_period',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_period' },
        },
        {
          title: '保养类型',
          dataIndex: 'maintenance_schedule_type',
          key: 'maintenance_schedule_type',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_schedule_type' },
        },
        {
          title: '计划完成日期',
          dataIndex: 'need_finish_time',
          key: 'need_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'need_finish_time' },
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '节点状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          scopedSlots: { customRender: 'process_node' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      yearOptions: [],
      deviceCodeList:[],
      maintenancePeriodList:[],
      userdepidCascaderSelected: [],
      equipmentMaintenanceItemList: [],
      queryInstallDate:null,
      queryInstallDate1:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      evaluationModalVisible:false,
      formData:{
        maintenance_order_id:'',
        property_man:'',
        property_audit_grade:'',
        property_audit_evaluation:'',
      },
      //这里面的数据属于必填项
      formRules: {
        property_audit_grade: [{required: true, message: '请选择评价'}],
        property_audit_evaluation: [{required: true, message: '请填写评价内容'}],
      },


      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "保养任务"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"保养管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.queryParams.order_date=start.substring(0,4)
    },
    queryInstallDate1(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime1 = start;
      this.queryParams.endtime1 = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMaintenanceUnitList();
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
      this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
    }
  },
  methods:{
    getMaintenanceUnitList(){
      getMaintenanceUnitListByCondition({userdepsid:this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.maintenanceUnitList = res.item
        }
      })
    },
    getTableData(firstPage) {
      if(this.userInfo.rolename==='维保组长'||this.userInfo.rolename==='维保组员'||this.userInfo.rolename==='自保组长'||this.userInfo.rolename==='自保组员'){
        getMaintenanceGroupByUserId({userid:this.userInfo.userid}).then(res=>{
          if(res && res.returncode == '0') {
            if (res.item.length > 0) {
              if (firstPage) {
                this.currentPage = 1;
              }
              this.tableLoading = true;
              let maintenancegroupid='';
              for(let i=0;i<res.item.length;i++){
                maintenancegroupid+=res.item[i].maintenancegroupid+','
              }
              let params = {
                ...this.queryParams,
                maintenancegroupid: maintenancegroupid,
                pageno: this.currentPage,
                pagesize: this.pagination.pageSize
              };
              getMaintenanceOrderListByMaintenanceGroupName(params).then(res => {
                this.tableLoading = false;
                if (res && res.returncode == '0') {
                  this.tableData = res.item;
                  this.pagination.total = res.count;
                }
              }).catch(err => {
                this.tableLoading = false;
              })
            }
          }else{
            this.tableLoading = false;
          }
        })
      }else{
        if(firstPage) {
          this.currentPage = 1;
        }
        this.tableLoading = true;
        let params = {
          ...this.queryParams,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMaintenanceOrderListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.queryInstallDate1 = [];
      this.$refs.queryForm.resetFields();
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
      }
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenance_order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenance_order_id', maintenance_order_id);
      if(type == 'delete') {
        this.deleteConfirm(maintenance_order_id, record)
      }else if(type == 'evaluate'){
        this.formData=record
        this.evaluationModalVisible=true
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportMaintenanceOrderList()
      }).catch(()=>{
      });
    },
    exportMaintenanceOrderList(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMaintenanceOrderListExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.maintenance_order_id);
      }).catch(()=>{

      }) ;
    },
    delete(maintenance_order_id) {
      this.showLoading();
      if(maintenance_order_id) {
        let params = {
          maintenance_order_id
        };
        deleteMaintenanceOrder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let startYear = 2023;
      for(let i=100; i>-1; i--) {
        this.yearOptions.push(startYear+i+'');
      }
      this.queryParams.order_date=currentYear+''
    },
    evaluation(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.formData.property_man=this.userInfo.username
          modifyMaintenanceOrder({...this.formData}).then(res => {
            if (res && res.returncode === '0') {
              this.evaluationModalVisible = false
              this.$message.success("评价成功")
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>

</style>