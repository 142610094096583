<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备类型" prop="equipment_type">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.equipment_type" @change="getEquipDirectoriesList(formData.equipment_type)">
            <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="设备编号" prop="equipment_num">-->
<!--          <a-input :disabled="showType=='detail'" v-model.trim="formData.equipment_num" />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="设备名称" prop="equipment_num">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model.trim="formData.equipment_num">
            <a-select-option v-for="(item,index) in equipmentNumList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="安装位置" prop="address">-->
<!--          <a-input :disabled="showType=='detail'" v-model.trim="formData.address" />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="维保形式" prop="maintenance_form">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.maintenance_form" @change="getMaintenanceUnitTask(formData.maintenance_form)">
            <a-select-option value="自保">自保</a-select-option>
            <a-select-option value="外委">外委</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="任务名称" prop="task_id">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.task_id" @change="getMaintenanceUnitTaskInfoList(formData.task_id)">
            <a-select-option v-for="(item,index) in maintenanceUnitTaskList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="formData.maintenance_form=='外委'" label="合同编号" prop="contract_num">
          <a-input :disabled="true" v-model.trim="formData.contract_num" />
        </a-form-model-item>
        <a-form-model-item label="维保单位" prop="maintenance_unit_name">
          <a-input :disabled="true" v-model.trim="formData.maintenance_unit_name" />
        </a-form-model-item>
        <a-form-model-item label="保养类型" prop="maintenance_schedule_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.maintenance_schedule_type" @change="getMaintenancePeriod(formData.maintenance_schedule_type)">
            <a-select-option v-for="(item,index) in equipmentMaintenanceItemList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划状态" prop="plan_status">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.plan_status">
            <a-select-option :value="1">启动</a-select-option>
            <a-select-option :value="0">停止</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="初次启动时间" prop="first_date">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.first_date" style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-divider></a-divider>
        <div style="color: red;margin-left: 70px;margin-top: -10px">填写下方运行计划请注意以下三点：</div>
        <div style="color: red;margin-left: 90px">1.保养周期分五类。半月保（BY）、月保（Y）、季保（J）、半年保（BN）、年保（N）<span style="font-size:large">(每月至多有三次保养)</span></div>
        <div style="color: red;margin-left: 90px;">2.保养时间用保养日期与保养类别组合填写；如7Y，表示7号月保，如果一个月内存在多次保养，请用英文逗号隔开，例如2BY,16BY,31BY，如果一个月没有任何保养，请输入0Y</div>
        <div style="color: red;margin-left: 90px;margin-bottom: 10px;font-size:large" v-if="maintenancePeriod">3.该计划保养类型有且只有{{maintenancePeriod}}{{count}}种保养周期，请务必不要填写错误！！！</div>
        <a-form-model-item label="计划年份" prop="year">
          <a-select :disabled="true" v-model="formData.year">
            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="" prop=""></a-form-model-item>
        <a-form-model-item label="一月" prop="month_one">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_one" @blur="formData.month_one=formData.month_one.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="二月" prop="month_two">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_two" @blur="formData.month_two=formData.month_two.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="三月" prop="month_three">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_three" @blur="formData.month_three=formData.month_three.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="四月" prop="month_four">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_four" @blur="formData.month_four=formData.month_four.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="五月" prop="month_five">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_five" @blur="formData.month_five=formData.month_five.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="六月" prop="month_six">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_six" @blur="formData.month_six=formData.month_six.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="七月" prop="month_seven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_seven" @blur="formData.month_seven=formData.month_seven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="八月" prop="month_eight">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eight" @blur="formData.month_eight=formData.month_eight.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="九月" prop="month_nine">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_nine" @blur="formData.month_nine=formData.month_nine.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十月" prop="month_ten">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_ten" @blur="formData.month_ten=formData.month_ten.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十一月" prop="month_eleven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eleven" @blur="formData.month_eleven=formData.month_eleven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十二月" prop="month_twelve">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_twelve" @blur="formData.month_twelve=formData.month_twelve.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addMaintenanceSchedule, getMaintenancePeriodByScheduleType,
  getMaintenanceScheduleListByCondition, getMaintenanceUnitTaskList, getMaintenanceUnitTaskListByCondition,
  modifyMaintenanceSchedule
} from "A/maintenance";
import {getMentenacegroupById, getMentenacegroupListByCondition} from "A/xtpz";
import {getCache} from "U/index";
import {getEquipDirectoriesList} from "A/facilityandequipment";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '620px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        maintenance_order_type:'0',
        monitorpointnum: '',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        address:'',
        maintenance_form:'',
        task_id: '',
        task_name: '',
        contract_num: '',
        maintenance_schedule_type:'',
        plan_status:'',
        month_one:'',
        month_two:'',
        month_three:'',
        month_four:'',
        month_five:'',
        month_six:'',
        month_seven:'',
        month_eight:'',
        month_nine:'',
        month_ten:'',
        month_eleven:'',
        month_twelve:'',
        maintenance_unit_id:'',
        maintenance_unit_name:'',
        maintenance_group_id:'',
        maintenance_group_name:'',
        maintenance_group_leader:'',
        maintenance_group_crew:'',
        year:'',
        first_date:null,
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        equipment_type: [{required: true, message: '请选择设备类型'}],
        equipment_num: [{required: true, message: '请选择设备名称'}],
        task_id: [{required: true, message: '请选择任务名称'}],
        maintenance_form: [{required: true, message: '请选择维保形式'}],
        maintenance_schedule_type: [{required: true, message: '请选择保养类型'}],
        plan_status: [{required: true, message: '请选择计划状态'}],
        maintenance_unit_id: [{required: true, message: '请选择自保单位'}],
        maintenance_group_id: [{required: true, message: '请选择自保单位'}],
        first_date: [{required: true, message: '请选择首次保养时间'}],
        month_one: [
          {required: true, message: '请输入一月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_two: [{required: true, message: '请输入二月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_three: [{required: true, message: '请输入三月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_four: [{required: true, message: '请输入四月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_five: [{required: true, message: '请输入五月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_six: [{required: true, message: '请输入六月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_seven: [{required: true, message: '请输入七月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_eight: [{required: true, message: '请输入八月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_nine: [{required: true, message: '请输入九月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_ten: [{required: true, message: '请输入十月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_eleven: [{required: true, message: '请输入十一月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_twelve: [{required: true, message: '请输入十二月份计划'},
          {
            validator: (rule, value, callback) => {
              if(/[YJN]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
      },
      monitorpointList:[],
      deviceCodeList:[],
      equipmentNumList:[],
      maintenanceUnitTaskList:[],
      equipmentMaintenanceItemList:[],
      maintenancedepidList: [],
      maintenanceGroupList:[],
      yearOptions: [],
      maintenancePeriod:"",
      count:"",
      maintenancePeriodList:[
          "半月保（BY）","月保（Y）","季保（J）","半年保（BN）","年保（N）"
      ],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getEquipDirectoriesList();
        this.getYearOptions();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    maintenancedepidList(val) {
      if(val && val.length) {
        this.formData.maintenance_unit_id = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenance_unit_id');
        this.getMaintenanceGroupList(this.formData.maintenance_unit_id)
      }else {
        this.formData.maintenance_unit_id = ''
      }
    },
    'formData.maintenance_form'(val){
      if(val==='自保'&&this.maintenancedepidList.length===0){
        this.formData.maintenance_group_id=""
        this.formData.task_id=""
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
    }
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.formData.contract_num=""
      this.formData.maintenance_group_id=""
      this.maintenanceGroupList=[]
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.maintenance_schedule_id) {
        if(this.showType == 'edit' || this.showType == 'detail'||this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              maintenance_schedule_id: this.detailData.maintenance_schedule_id
            }
            getMaintenanceScheduleListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if(this.formData.maintenance_form==='自保'){
                  this.initDeptOptionsByUserdepid(this.formData.maintenance_unit_id, 'maintenancedepidList', this.userInfo.usertype != '3')
                }
                if(this.showType === 'paste'){
                  this.formData.plan_status=this.detailData.plan_status
                  this.formData.month_one=this.detailData.month_one
                  this.formData.month_two=this.detailData.month_two
                  this.formData.month_three=this.detailData.month_three
                  this.formData.month_four=this.detailData.month_four
                  this.formData.month_five=this.detailData.month_five
                  this.formData.month_six=this.detailData.month_six
                  this.formData.month_seven=this.detailData.month_seven
                  this.formData.month_eight=this.detailData.month_eight
                  this.formData.month_nine=this.detailData.month_nine
                  this.formData.month_ten=this.detailData.month_ten
                  this.formData.month_eleven=this.detailData.month_eleven
                  this.formData.month_twelve=this.detailData.month_twelve
                }
                this.getMaintenancePeriod(this.formData.maintenance_schedule_type)
                getMaintenanceUnitTaskList({task_type:this.formData.maintenance_form==='外委'?'0':this.formData.maintenance_form==='自保'?'1':'',monitorpointnum:this.formData.monitorpointnum}).then(res=>{
                  if(res&&res.returncode=='0'){
                    this.maintenanceUnitTaskList=res.item
                  }
                })
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.first_date=moment(this.formData.first_date).format("YYYYMMDD")
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addMaintenanceSchedule(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyMaintenanceSchedule(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getEquipDirectoriesList(equipment_type){
      getEquipDirectoriesList({equipment_type:equipment_type,is_history:'0'}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList=res.item
        }
      })
    },
    getMaintenanceUnitTask(maintenance_form){
      // this.formData.task_id=''
      getMaintenanceUnitTaskList({task_type:maintenance_form==='外委'?'0':maintenance_form==='自保'?'1':'',monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.maintenanceUnitTaskList=res.item
        }
      })
    },
    getMaintenanceUnitTaskInfoList(task_id){
      getMaintenanceUnitTaskListByCondition({task_id:task_id}).then(res=>{
        if(res&&res.returncode=='0'){
          this.formData.maintenance_unit_id=res.item[0].maintenance_unit_id
          this.formData.maintenance_unit_name=res.item[0].maintenance_unit_name
          this.formData.maintenance_group_id=res.item[0].maintenance_group_id
          this.formData.maintenance_group_name=res.item[0].maintenance_group_name
          this.formData.contract_num=res.item[0].contract_num
        }
      })
    },
    getMaintenanceGroupList(maintenancedepid){
      getMentenacegroupListByCondition({userdepid:maintenancedepid}).then(res => {
        if (res && res.returncode == '0') {
          this.maintenanceGroupList=res.item
          this.formData.maintenance_group_leader=res.item[0].groupmenname
          this.formData.maintenance_group_crew=res.item[0].memberList.length ? res.item[0].memberList.map(item => item.username).join(',') : ''
        }
      })
    },
    getMemberList(maintenance_group_id){
      getMentenacegroupById({maintenancegroupid:maintenance_group_id}).then(res=>{
        if(res&&res.returncode=='0'){
          let result="";
          this.formData.maintenance_group_leader=res.item.groupmenname
          res.item.memberList.forEach(item => {
            if(result) {
              result += ','
            }
            result += item.username
          })
          this.formData.maintenance_group_crew=result
        }
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=3; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
    },
    getMaintenancePeriod(maintenance_schedule_type){
      this.maintenancePeriod=""
      getMaintenancePeriodByScheduleType({maintenance_schedule_type:maintenance_schedule_type}).then(res=>{
        if(res&&res.returncode==='0'){
          this.count=res.item.length
          for(let i=0;i<res.item.length;i++){
            this.maintenancePeriod+=(i===res.item.length-1)?(this.maintenancePeriodList[res.item[i].dicvalue]):(this.maintenancePeriodList[res.item[i].dicvalue]+"、")
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>